import {Controller} from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
    static targets = ["input", "previews", "dropArea"];

    connect() {
        this.setupDragAndDrop();
        this.setupSortable();
        console.log("Image Preview Controller Connected");
    }

    previewImages() {
        const input = this.inputTarget;
        const previewsContainer = this.previewsTarget;
        const existingFiles = Array.from(previewsContainer.querySelectorAll(".image-container img")).map(img => img.src);

        Array.from(input.files).forEach((file, index) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                if (!existingFiles.includes(event.target.result)) {
                    const div = document.createElement("div");
                    div.classList.add("image-container");
                    div.dataset.index = existingFiles.length + index;
                    if (existingFiles.length + index === 0) {
                        div.classList.add("first-image");
                    }

                    const img = document.createElement("img");
                    img.src = event.target.result;
                    img.classList.add("preview-image");

                    const span = document.createElement("span");
                    span.classList.add("remove-image");
                    span.innerHTML = "&times;";
                    span.onclick = () => {
                        div.remove();
                        this.updateImageOrderInput();
                        const dt = new DataTransfer();
                        Array.from(input.files).forEach((f, i) => {
                            if (i !== index) {
                                dt.items.add(f);
                            }
                        });
                        input.files = dt.files;
                        this.updateIndexes();
                        this.updateDropArea();
                    };

                    div.appendChild(img);
                    div.appendChild(span);
                    previewsContainer.appendChild(div);
                    this.updateDropArea();
                    this.updateImageOrderInput();
                }
            };
            reader.readAsDataURL(file);
        });
    }

    removeImage(event) {
        const imageContainer = event.target.closest('.image-container');
        const signedId = event.target.getAttribute('data-signed-id');
        const productId = document.getElementById("image-previews").getAttribute("data-product-id");

        if (!signedId) {
            console.error("Signed ID not found.");
            return;
        }

        if (!productId) {
            console.error("Product ID not found.");
            return;
        }

        // Send an AJAX request to delete the image
        fetch(`/products/${productId}/destroy_image?signed_id=${signedId}`, {
            method: "DELETE",
            headers: {
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    console.log("Image deleted successfully");
                    imageContainer.remove(); // Remove the image container from the DOM
                    this.updateImageOrderInput(); // Update the hidden input for image order if needed
                } else {
                    console.error("Error deleting image:", data.error);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    addImageClick(event) {
        event.preventDefault();
        this.inputTarget.click();
    }

    setupDragAndDrop() {
        const previewsContainer = this.previewsTarget;
        const dropArea = this.dropAreaTarget;
        let draggedItem = null;

        dropArea.addEventListener("dragover", (e) => {
            e.preventDefault();
            dropArea.classList.add("drop-area-active"); // Add active class
        });

        dropArea.addEventListener("dragleave", () => {
            dropArea.classList.remove("drop-area-active"); // Remove active class
        });

        dropArea.addEventListener("drop", (e) => {
            e.preventDefault();
            e.stopPropagation();
            dropArea.classList.remove("drop-area-active"); // Remove active class
            this.inputTarget.files = e.dataTransfer.files;
            this.previewImages();
        });
    }
  setupSortable() {
    const previewsContainer = this.previewsTarget;

    Sortable.create(previewsContainer, {
      animation: 150,
      onEnd: () => {
        const signedIds = Array.from(previewsContainer.querySelectorAll(".remove-image")).map((span) =>
            span.getAttribute("data-signed-id")
        );

        console.log("Updated order:", signedIds);
        this.updateFirstImage();
        const productId = previewsContainer.getAttribute("data-product-id");
        fetch(`/products/${productId}/update_image_order`, {
          method: "POST",
          headers: {
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ images_order: signedIds }),
        })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                console.log("Image order updated successfully:", data.message);
              } else {
                console.error("Error updating image order:", data.error);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
      },
    });
  }

  updateFirstImage() {
    const previewsContainer = this.previewsTarget;
    const allImages = previewsContainer.querySelectorAll(".image-container");

    allImages.forEach((image, index) => {
      if (index === 0) {
        image.classList.add("first-image");
      } else {
        image.classList.remove("first-image");
      }
    });
  }



  updateIndexes() {
        const previewsContainer = this.previewsTarget;
        const imageContainers = previewsContainer.querySelectorAll(".image-container:not(.add-image-container)");
        imageContainers.forEach((container, index) => {
            container.dataset.index = index;
            if (index === 0) {
                container.classList.add("first-image");
            } else {
                container.classList.remove("first-image");
            }
        });
    }

    updateImageOrderInput() {
        const previewsContainer = this.previewsTarget;
        const imageOrderInputs = document.querySelectorAll('.image-order-input');
        const imageContainers = previewsContainer.querySelectorAll(".image-container:not(.add-image-container) img");

        imageOrderInputs.forEach((input, index) => {
            input.value = imageContainers[index].src;
        });

        const deletedImagesInput = document.getElementById('deleted-images-input');
        const deletedImages = Array.from(document.querySelectorAll('.image-container img')).filter(img => !img.closest('.image-container'));
        deletedImagesInput.value = deletedImages.map(img => img.src).join(',');
    }

    updateDropArea() {
        const previewsContainer = this.previewsTarget;
        const dropArea = this.dropAreaTarget;
        const imageContainers = previewsContainer.querySelectorAll(".image-container");

        if (imageContainers.length > 0) {
            dropArea.classList.add("small-file-drop-area");
            dropArea.querySelector(".file-msg").style.display = "none";
            dropArea.querySelector(".fake-btn").style.display = "none";
        } else {
            dropArea.classList.remove("small-file-drop-area");
            dropArea.querySelector(".file-msg").style.display = "";
            dropArea.querySelector(".fake-btn").style.display = "";
        }

        previewsContainer.appendChild(dropArea);
    }

    dropHandler(event) {
        event.preventDefault();
        event.stopPropagation();
        this.inputTarget.files = event.dataTransfer.files;
        this.previewImages();
    }

    dragOverHandler(event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
