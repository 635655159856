import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["totalPrice", "designPrice", "totalPriceWithoutDesign", "designInput", "commissionPrice"]

  priceChanged(e) {
    const currency = this.element.dataset.currency;
    this.totalPriceWithoutDesignTargets.forEach((target, index) => {
      var design = this.parseFee(this.designInputTarget.value, currency) || 0;
      var printing_shipping = this.parseFee(target.dataset.price, currency);
      var commission = this.calculateCommission(design, printing_shipping);
      var total = design + printing_shipping + commission;

      this.totalPriceTargets[index].innerText = this.formatCurrency(total, currency);
      this.designPriceTargets[index].innerText = this.formatCurrency(design, currency);
      this.commissionPriceTargets[index].innerText = this.formatCurrency(commission, currency);
    });
  }

  parseFee(amount, currency) {
    const symbols = { EUR: "€", USD: "$", GBP: "£", CAD: "CA$", AUD: "AU$" };
    const symbol = Object.values(symbols).find((s) => amount.includes(s)) || "";
    return parseFloat(amount.replace(",", ".").replace(symbol, "").trim());
  }

  calculateCommission(design, printing_shipping) {
    const TELEPORT_FEE = 0.15;
    return (design + printing_shipping) * TELEPORT_FEE;
  }

  formatCurrency(amount, currency) {
    const symbols = { EUR: "€", USD: "$", GBP: "£", CAD: "CA$", AUD: "AU$" };
    const symbol = symbols[currency] || currency;
    return `${symbol}${amount.toFixed(2).replace(".", ",")}`;
  }
}
